// This file is for New world embedded header cart update

import Cart from '../models/cart/cart';
import Modal from '../components/modal/modal';
import getBrand from 'dw-brand-identify';
import { clickEventDL, pageInteractionDl } from 'dw-global/apps/dataLayer';
import request from '../utilities/request';

/**
 * # Embedded Cart View
 * Create a new cart, this is designed for the new header
 */
 class EmbeddedCartController {
	constructor() {
		this._brandObj = getBrand();
		this._events = {};
		this.nonEmptyCart = false;
		Cart.subscribe(Cart.actions.UPDATED_FAILURE, () => {
			// this.el.classList.remove('loading');
			this.failure('We are having problems updating your cart at the moment.');
		});

		Cart.subscribe(Cart.actions.ADD_SUCCESSFUL, (itemData) => {
			this.triggerItemAddedEvent(itemData);
			// Implement custom event
			if (typeof dataLayer !== 'undefined') {
				this.gaEventCartAdd(itemData);
			};
			document.dispatchEvent(new CustomEvent("event-algolia-converted", {
                bubbles: true,
                cancelable: false,
				detail: itemData
            }));
		});

		Cart.subscribe(Cart.actions.ADD_FAILURE, (e) => {
			// if the failure has a string message, show that
			if (typeof e === 'string') {
				this.failure(e);
			} else {
				/*
				@todo add brand identifier into this to get the differnt numbers
				*/
				this.failure(`Sorry, we are having problems adding your product, please try again or contact the call centre on ${this._brandObj.telephone}`);
			}
		});
		// bind all the buttons
		Array.from(document.querySelectorAll('.js-add-to-cart')).forEach((btn) => {
			this.addEvent(btn, 'click', this.addToCart.bind(this));
		});
		Array.from(document.querySelectorAll('.js-add-multi-items')).forEach((btn) => {
			this.addEvent(btn, 'click', this.addToCart.bind(this));
		});

		Array.from(document.querySelectorAll('.js-quick-cart')).forEach((btn) => {
			this.addEvent(btn, 'click', this.addToCart.bind(this));
		});

		Array.from(document.querySelectorAll('.js-add-xmas-items')).forEach((btn) => {
			this.addEvent(btn, 'click', this.addToCart.bind(this));
		});
		Array.from(document.querySelectorAll('.js-add-bundle-to-cart')).forEach((btn) => {
			this.addEvent(btn, 'click', this.addToCart.bind(this));
		});
		Array.from(document.querySelectorAll('.DLclick')).forEach((btn) => {
			this.addEvent(btn, 'click', this.pageInteraction.bind(this, btn));
		});
		Array.from(document.querySelectorAll('.signupEmailDLClick')).forEach((btn) => {
			this.addEvent(btn, 'click', this.pageInteraction.bind(this, btn));
		});

		// list for a custom event
		document.body.addEventListener('add-to-cart', (e) => {
			this.addToCart({
				target: e.detail,
			})
		});

		document.addEventListener("add-cart-successful", (e) => {
			if(e && e.detail) {
				Cart.fetch().then(() => {
					Cart.dispatch(Cart.actions.ADD_SUCCESSFUL, e.detail);
				});
			}
		});

		document.addEventListener("remove-cart-successful", (e) => {
			if(e && e.detail) {
				Cart.fetch().then(() => {
					Cart.dispatch(Cart.actions.REMOVE_SUCCESSFUL, e.detail);
				});
			}
		});

		// fetch the cart
		Cart.fetch();
	}

	get element() {
		return this.el;
	}

	set element(el) {
		this.el = el;
	}

	get nextEventId() {
		this._id = this._id && this._id > 0 ? this._id++ : 0;
	}

	_eventOptionsSupported() {
		// work out if the passive event listener is supported
		let passiveSupported = false;	
		try {
			const options = Object.defineProperty({}, 'passive', {
				get: () => { passiveSupported = true }
			});
			window.addEventListener('test', options, options);
  			window.removeEventListener('test', options, options);
		} catch(e) {
			passiveSupported = false;
		}
		return passiveSupported;
	}

	addEvent(element, action, func, options = null, useCapture = false) {
		// if the options flag is supported
		let event;
		if(element !== null){
			event = this._eventOptionsSupported() ? 
			// pass the options in, and useCapture second
			element.addEventListener(action, func, options, useCapture) :
			// useCapture is the 3rd argument in IE
			element.addEventListener(action, func, useCapture);
		}
		// get this event ID
		const id = this.nextEventId;
		// store the event into the class, this is so we can delete it
		this._events[id] = {id, event, element, action, func, options, useCapture};
		// return the ID for tracking
		return id;
	}

	getItem(itemcode) {
        return request.product.get({
            itemcode
        }).then((res) => res.json()).then((res) => {
            if (res.statusCode !== 0) {
                throw new Error(res.errorResponse.message);
            }
            return res.response;
        }).catch((error) => {
            console.log(error.message);
        });
    }

	async gaEventCartAdd(itemData) {
		const event =  itemData ? 'addToCart' : 'addToEmptyCart';
		const ADLevent = itemData && this.nonEmptyCart === true ? 'addToCart' : 'InitiateCart';
		itemData.name = ADLevent === 'addToCart' ? 'add to cart' : 'add to empty cart';
		itemData.clickText = 'Add to Basket';
		const productdetails = await this.getItem(itemData.itemcode);
		clickEventDL(ADLevent, itemData, productdetails);
		this.nonEmptyCart = true;
        dataLayer.push({
            'event': event,
            'ecommerce': {
                'currencyCode': 'GBP',
                'add': {
                    'products': [{
                        'name': '',
                        'id': itemData.itemcode,
                        'price': itemData.price,
						'webHeadline': itemData?.webHeadline,
                        'brand': '',
                        'category': '',
                        'quantity': itemData.quantity
                    }]
                }
            }
        });
	}

	pageInteraction(e, element){
		pageInteractionDl(element.currentTarget);
	}
	/**
	 * ## addToCart
	 * Add an item to the cart, this is designed to work with the old school
	 * website.
	 *
	 * @param {Event} e The event object
	 */
	addToCart(e) {
		const btn = e.target;
		let btnText = e.target.innerText;
		const code = btn.getAttribute('data-itemcode');
		// stop the old minicart from doing anything. This works because the old
		// minicart binds all it's actions to the body element
		if (e.stopPropagation) {
			e.stopPropagation();
		}

		btn.classList.add('btn-loading');
		btn.innerText = 'Adding...';

		const finished = () => {
			btn.classList.remove('btn-loading');
			btn.innerText = btnText;
		}

		if (btn.classList.contains('js-add-multi-items') || btn.classList.contains('js-add-bundle-to-cart')) {
			let items = [];
			if (btn.getAttribute('data-items')) {
				// multiple items
				const dataItems = btn.getAttribute('data-items');
				items = dataItems.split(",").map(item => item.trim());
			} else {
				// bulk deals add
				items = Array.from(
					btn
					// well-orderform
					.parentNode
					// col-sm-3
					.parentNode
					// js-container
					.parentNode
					// find all the items
					.querySelectorAll('.js-bulk-item')
				).map((item) => item.getAttribute('data-item'));
			}

			let cartItems = items.map((item) => {
				return {
					'itemCode': item,
					'quantity': '1',
				};
			});

			// remove the styling and text afterwards
			Cart.subscribe(Cart.actions.ADD_MULTI_SUCCESSFUL, finished);
			Cart.subscribe(Cart.actions.ADD_MULTI_FAILURE, finished);

			// add the item to the cart, this will fire a render
			Cart.addMultipleItems(cartItems);

		}
		else {
		if (btn.classList.contains('js-add-xmas-items')) {
			// single item
			const code = btn.getAttribute('data-itemcode');
			const price = btn.getAttribute('data-salesprice');
			const quantity = btn.getAttribute('data-qty');

			// remove the styling and text afterwards
			Cart.subscribe(Cart.actions.ADD_SUCCESSFUL, finished);
			Cart.subscribe(Cart.actions.ADD_FAILURE, finished);
			Cart.subscribe(Cart.actions.ADD_END, finished);

			// add the item to the cart, this will fire a render
			Cart.addItem(code, quantity, price);
		} else if (btn.classList.contains('js-quick-cart')) {	
			// single item
			const code = btn.getAttribute('data-itemcode');
			const price = btn.getAttribute('data-salesprice');
			const quantity = btn.getAttribute('data-qty');

			// remove the styling and text afterwards
			Cart.subscribe(Cart.actions.ADD_SUCCESSFUL, finished);
			Cart.subscribe(Cart.actions.ADD_FAILURE, finished);
			Cart.subscribe(Cart.actions.ADD_END, finished);
			// add the item to the cart, this will fire a render
			Cart.addItem(code, quantity, price);
		} else {
			const submission = btn.getAttribute('data-submission');
			const checkedOption = btn.closest('.js-orderform').querySelector('input[type="radio"]:checked');
			const code = checkedOption.getAttribute('data-itemcode');
			const price = checkedOption.getAttribute('data-salesprice');
			const webHeadline = checkedOption.getAttribute('data-webheadline');
			const stockQty = parseInt(checkedOption.getAttribute('data-count'), 10);
			const stockSummary = checkedOption.getAttribute('data-summary');
			const errorContainer = document.getElementById('error-container');

			// remove the styling and text afterwards
			Cart.subscribe(Cart.actions.ADD_SUCCESSFUL, finished);
			Cart.subscribe(Cart.actions.ADD_FAILURE, finished);
			Cart.subscribe(Cart.actions.ADD_END, finished);

			let quantity, cartQuantity = 0;
			if (submission === 'tiered') {
				quantity = checkedOption.getAttribute('data-qty');
			} else {
				const qty = parseInt(btn.closest('.js-qty').querySelector('input[id=qty]').value, 10);
				if (qty) {
					quantity = qty;
				} else {
					quantity = 1;
				}
			}
			
			Cart.items.forEach((item) => {
				if(item._item.sku.itemCode === code) {
					cartQuantity = parseInt(item._item.quantity, 10);
				}
			})
			if (stockSummary === "low_stock" && (quantity > stockQty || (quantity + cartQuantity) > stockQty)) {
				if(errorContainer) {
					errorContainer.style.display = "block";
				}
				btn.classList.remove('btn-loading');
				btn.innerText = btnText;
			} else {
				if (errorContainer !== null) {
					errorContainer.style.display = "none";
				}
				// add the item to the cart, this will fire a render
				Cart.addItem(code, parseInt(quantity, 10), price, webHeadline);
			}
		}
	}
	}

	triggerItemAddedEvent(itemData) {
		document.dispatchEvent(new CustomEvent('item-added-into-cart-successful', {
			bubbles: true,
			detail: itemData
		}));
	}

	failure(msg) {
		// create the modal
		const modal = new Modal({
			title: 'Shopping Basket',
			body: msg,
			containsError: true,
			showFooter: false,
		});
		// open the modal
		modal.open();
	}
}


export default EmbeddedCartController;