import request from '../../utilities/request';
import Component from '../component';

import americanExpressLogo from './logos/americanexpress.svg';
import applePayLogo from './logos/applepay.svg';
import googlePayLogo from './logos/googlepay.svg';
import mastercardLogo from './logos/mastercard.svg';
import paypalLogo from './logos/paypal.svg';
import visaLogo from './logos/visa.svg';

const CREDIT_CARD = 'creditCard';
const PAYPAL = 'paypal';
const APPLE_PAY = 'applePay';
const GOOGLE_PAY = 'googlePay';

class PaymentLogos extends Component {
	constructor(tag, brand, height) {
		super(tag);
		this.availablePaymentMethods = [];
		this.height = height || '30px';
		this.brand = brand;
		this.loadPaymentOptions();
	}

	async loadPaymentOptions() {
		const paymentMethods = await request.checkout.paymentOptions().then((res) => res.json());
		this.availablePaymentMethods = paymentMethods.response;
		this.render();
	}

	isWebView(){
		const userAgent = /DWAPP/i.test(navigator.userAgent);
		return !userAgent;
	}

	render() {
		const paymentMethods = [];
		const section = document.createElement('section');
		section.classList.add('payment-logos');

		this.availablePaymentMethods.forEach((method) => {
			if (method.paymentMode === CREDIT_CARD) {
				if (this.brand === 'hsb') {
					// HSBC only accepts mastercard
					paymentMethods.push(`<img src="${mastercardLogo}" alt="Mastercard" height="${this.height}" />`);
					return;
				}
				paymentMethods.push(`<img src="${mastercardLogo}" alt="Mastercard" height="${this.height}" />`);
				paymentMethods.push(`<img src="${visaLogo}" alt="Visa" height="${this.height}" />`);
				paymentMethods.push(`<img src="${americanExpressLogo}" alt="American Express" height="${this.height}" />`);
			}

			if (method.paymentMode === PAYPAL) {
				paymentMethods.push(`<img src="${paypalLogo}" alt="PayPal" height="${this.height}"/>`);
			}

			if (method.paymentMode === GOOGLE_PAY && this.isWebView()) {
				paymentMethods.push(`<img src="${googlePayLogo}" alt="G Pay" class="gpay" height="${this.height}" />`);
			}

			if (method.paymentMode === APPLE_PAY && window?.ApplePaySession?.canMakePayments()) {
				paymentMethods.push(`<img src="${applePayLogo}" alt="Apple Pay" height="${this.height}" />`);
			}
		});
		section.innerHTML = paymentMethods.join('');
		this.el.appendChild(section);
	}
}

export default PaymentLogos;
