import request from '../../utilities/request';
import Model from '../model';
import Item from './item';
import User from '../user/user';
import getBrand from 'dw-brand-identify';
import { clickEventDL } from 'dw-global/apps/dataLayer';
import Contentful from '../contentful/contentful';
import { CONTENT_TYPE } from '../contentful/config';

const UNLIMITED_CODES = ['00067SV', '00071SV', '00068SV'];
const CART_ID_COOKIE = 'cart-id';
const CSRF_COOKIE = 'csrf';

/**
 * @class  Cart
 */
class CartModel extends Model {

	constructor() {
		super();
		this._items = [];
		this._total = 0;
		this._savings = 0;
		this._brandObj = getBrand();
		// if the user changes state (login/logout) we need to make sure the cart
		// is up to date
		User.subscribe(User.actions.LOGOUT_SUCCESSFUL, this.fetch.bind(this));
		User.subscribe(User.actions.LOGIN_SUCCESSFUL, this.fetch.bind(this));
		document.addEventListener("event-remove-cart-item", (e) => {
			let itemData = {
				itemcode: e.detail.itemcode,
				cartContents: e.detail.data,
				quantity: e.detail.quantity,
				price: e.detail.price,
				name: 'remove from cart',
				clickText: 'remove',
			}
			const event = "removeFromCart";
			clickEventDL(event, itemData);
		});
		document.addEventListener("event-addUpdate-cart-item", (e) => {
			let itemData = {
				itemcode: e.detail.itemcode,
				cartContents: e.detail.data,
				quantity: e.detail.quantity,
				price: e.detail.price,
				name: 'update cart',
				clickText: 'add',
			}
			const event = e.detail.eventName;
			clickEventDL(event, itemData, e.detail.data);
		});
		document.addEventListener("event-removeUpdate-cart-item", (e) => {
			let itemData = {
				itemcode: e.detail.itemcode,
				cartContents: e.detail.data,
				quantity: e.detail.quantity,
				price: e.detail.price,
				name: 'update cart',
				clickText: 'remove',
			}
			const event = e.detail.eventName;
			clickEventDL(event, itemData, e.detail.data);
		});
	}

	/**
	 * List of actions associated with this model. They can be accessed via
	 * `User.actions.UPDATED_START`.
	 * 
	 * @return {Object}
	 */
	get actions() {
		return {
			// update cart
			UPDATED_START: 'cart::updated-start',
			UPDATED_SUCCESSFUL: 'cart::updated-successful',
			UPDATED_FAILURE: 'cart::updated-failure',
			// add item to cart
			ADD_START: 'cart::add-start',
			ADD_SUCCESSFUL: 'cart::add-successful',
			ADD_FAILURE: 'cart::add-failure',
			ADD_END: 'cart::add-end',
			// add item to cart
			ADD_MULTI_START: 'cart::add-start',
			ADD_MULTI_SUCCESSFUL: 'cart::add-successful',
			ADD_MULTI_FAILURE: 'cart::add-failure',
			// add batch of items to cart
			ADD_BATCH_START: 'cart::add-batch-start',
			ADD_BATCH_SUCCESSFUL: 'cart::add-batch-successful',
			ADD_BATCH_FAILURE: 'cart::add-batch-failure',
			// remove item from cart
			REMOVE_START: 'cart::remove-start',
			REMOVE_SUCCESSFUL: 'cart::remove-successful',
			REMOVE_FAILURE: 'cart::remove-failure',
		}
	}

	/**
	 * Gets a list of all the items in the cart
	 * @return {array}
	 */
	get items() {
		return this._items;
	}

	get savings() {
		return this._savings;
	}

	get bottleCount() {
		return this._bottleCount;
	}

	get total() {
		return this._total;
	}

	/**
	 * Fetch the users cart
	 * @return {Promise} [description]
	 */
	fetch() {
		this.dispatch(this.actions.UPDATED_START);
		return request.cart.list().then(res => res.json()).then((json) => {
			// if we don't have a valid respone throw an error
			if (!json.response && json.statusCode !== 0) {
				throw json.statusMessage;
			}

			const data = json.response;
			// convert all the items into Items
			this._items = data.lineItems.map((item) => {
				return new Item(item);
			});

			//hide unlimited banner in the account page
			setTimeout(() => {
				data.lineItems.map((item) => {
					if (item.lineItemIdentification.itemCode === 'MEM01UTD' || item.lineItemIdentification.itemCode === '00067SV' || item.lineItemIdentification.itemCode === '00071SV' || item.lineItemIdentification.itemCode === '00068SV') {
						$('#account-container .unlimited-container').css('display', 'none');
					}
				})
			}, 300);

			// save the total
			this._total = (this._brandObj.id != 'hsb') ? data.orderPriceInfo.rawSubtotal : data.orderPointsInfo.total;
			this.numBottles = data.numBottles;
			this._savings = data.orderPriceInfo.savings;
			// tell everyone we have finished
			this.dispatch(this.actions.UPDATED_SUCCESSFUL, this);
			if (typeof window.dataLayer !== 'undefined') {
				window.dataLayer.push({
					'event': 'cartUpdated',
					'shoppingBasket': data.lineItems,
				});
			}
			// clickEventDL('cartUpdated', {'cartContents': data.lineItems} );
		}).catch((e) => {
			this.dispatch(this.actions.UPDATED_FAILURE, e);
		});
	}

	addBatchItems(cartItems) {
		// tell everyone we are adding
		this.dispatch(this.actions.ADD_BATCH_START);

		// make the request
		return request.cart.batch({
			cartItems,
		}).then(res => res.json()).then((json) => {

			// refetch the cart with the updated contents
			if (json.statusCode >= 400 && json.statusCode < 600) {
				throw new Error(json.errorResponse.message);
			}

			let cartContents = [];
			if (json && json.response && json.response.lineItems.length > 0) {
				cartContents = json.response.lineItems;
			}


			this.dispatch(this.actions.ADD_BATCH_SUCCESSFUL);
			return this.fetch().then(() => {
				const itemData = {
					cartContents,
				}
				// tell everyone we have finished
				this.dispatch(this.actions.ADD_SUCCESSFUL, itemData);
				clickEventDL('cartAdded', itemData);
				if (typeof window.dataLayer !== 'undefined') {
					window.dataLayer.push({
						'event': 'cartAdded',
					});
				}
			});
		}).catch((e) => {
			this.dispatch(this.actions.ADD_FAILURE, e);
		});
	}

	addMultipleItems(cartItems) {
		// tell everyone we are adding
		this.dispatch(this.actions.ADD_MULTI_START);

		// make the request
		return request.cart.multi({
			cartItems,
		}).then(res => res.json()).then((json) => {
			// refetch the cart with the updated contents
			if (json.statusCode >= 400 && json.statusCode < 600) {
				let errorMessage = json.statusMessage ? json.statusMessage : `Sorry, we are having problems adding your product, please try again or contact the call centre on ${this._brandObj.telephone}`;
				throw errorMessage;
			}
			let cartContents = [];
			if (json && json.response && json.response.lineItems.length > 0) {
				cartContents = json.response.lineItems;
			}

			return this.fetch().then(() => {
				const itemData = {
					cartContents,
				}
				// tell everyone we have finished
				this.dispatch(this.actions.ADD_SUCCESSFUL, itemData);
				clickEventDL('cartAdded', itemData);
				if (typeof window.dataLayer !== 'undefined') {
					window.dataLayer.push({
						'event': 'cartAdded',
					});
				}
			});
		}).catch((e) => {
			this.dispatch(this.actions.ADD_FAILURE, e);
		});
	}

	async addItem(code, quantity = 1, price, webHeadline = '') {
		// tell everyone we are adding
		this.dispatch(this.actions.ADD_START);

		// you can't add unlimted if you already have unlimited
		if (User.isUnlimited && UNLIMITED_CODES.indexOf(code) !== -1) {
			this.dispatch(this.actions.ADD_FAILURE, 'You are already an unlimited member');
			return;
		}

		// make the request
		this.addItemToBasket(code, quantity, price, webHeadline)
	}

	hasCookie(cookieName) {
		const cookies = document.cookie.split(';');
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i].trim();
			if (cookie.startsWith(cookieName + '=')) {
				return true;
			}
		}
		return false;
	}

	getCookieValue(cookieName) {
		const cookies = document.cookie.split(';');
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i].trim();
			if (cookie.startsWith(cookieName + '=')) {
				return cookie.substring(cookieName.length + 1);
			}
		}
		return null;
	}

	async addItemToBasket(code, quantity, price, webHeadline) {
		let cartId = this.getCookieValue(CART_ID_COOKIE);
		let isNewCartAvailable = window.isNewCartEnabled;
		
		if (isNewCartAvailable && this.hasCookie(CART_ID_COOKIE) && cartId) {
			const addToCartCatch = (event) => {
				console.log('evt Listener for ATC error:', event);
				if (event.detail.error === false) {
					this.dispatch(this.actions.ADD_END);
				} else {
					this.dispatch(this.actions.ADD_FAILURE, event?.detail?.message?.response?.data?.message);
				}
				console.log('Remove event listener');
				// Remove the event listener after the handler has been executed
				document.removeEventListener('aws-add-to-cart-status', addToCartCatch);
				return null;
			}
		
			document.addEventListener('aws-add-to-cart-status', addToCartCatch);
			document.dispatchEvent(new CustomEvent('aws-add-to-cart', {
				bubbles: true,
				detail: [{
					itemCode: code,
					quantity: quantity,
				}]
			}));
			console.log('Custom event dispatched');
			return null;
		}
		
		document.dispatchEvent(new CustomEvent('item-added-into-cart-successful', {
			bubbles: true,
			detail: {
				itemcode: code,
				quantity: quantity,
			}
		}));

		return request.cart.add({
			itemtype: 'itemcode',
			itemcode: code,
			quantity: quantity,
		}).then(res => res.json()).then((json) => {
			// refetch the cart with the updated contents
			if (json.statusCode >= 400 && json.statusCode < 600) {
				let errorMessage = json.statusMessage ? json.statusMessage : `Sorry, we are having problems adding your product, please try again or contact the call centre on ${this._brandObj.telephone}`;
				throw errorMessage;
			}
			let cartContents = [];
			if (json && json.response && json.response.lineItems.length > 0) {
				cartContents = json.response.lineItems;
			}

			// Filter cart array to look for the item we have just added
			let mostRecentItemAdded = cartContents.filter(function (el) {
				return el.product.itemCode === code;
			});

			let recentItemDetails = {};

			// Build a new object with data we need about the recently added item
			if (mostRecentItemAdded && mostRecentItemAdded.length > 0) {
				recentItemDetails = {
					bottleCount: mostRecentItemAdded[0].bottleCount ? mostRecentItemAdded[0].bottleCount : '',
					caseBottleCount: mostRecentItemAdded[0].caseBottleCount ? mostRecentItemAdded[0].caseBottleCount : '',
					mixed: mostRecentItemAdded[0].product.mixed
				}
			}

			return this.fetch().then(() => {
				const itemData = {
					itemcode: code,
					webHeadline,
					quantity: quantity,
					price: price,
					cartContents,
					recentItemDetails
				}
				// tell everyone we have finished
				this.dispatch(this.actions.ADD_SUCCESSFUL, itemData);
				// clickEventDL('cartAdded', itemData);
				if (typeof window.dataLayer !== 'undefined') {
					window.dataLayer.push({
						'event': 'cartAdded',
					});
				}
			});
		}).catch((e) => {
			this.dispatch(this.actions.ADD_FAILURE, e);
		});
	}

	removeItem(code, price, webHeadline = '') {
		// tell everyone we are removing
		this.dispatch(this.actions.REMOVE_START);

		// make the request
		return request.cart.remove({
			itemtype: 'itemcode',
			itemcode: code,
		}).then(res => res.json()).then((json) => {
			// refetch the cart with the updated contents
			if (json.statusCode >= 400 && json.statusCode < 600) {
				let errorMessage = json.statusMessage ? json.statusMessage : `Sorry, we are having problems adding your product, please try again or contact the call centre on ${this._brandObj.telephone}`;
				throw errorMessage;
			}
			let cartContents = [];
			if (json && json.response && json.response.lineItems.length > 0) {
				cartContents = json.response.lineItems;
			}

			return this.fetch().then(() => {
				const itemData = {
					itemcode: code,
					webHeadline,
					quantity: quantity,
					price: price,
					cartContents,
				}
				// tell everyone we have finished
				this.dispatch(this.actions.REMOVE_SUCCESSFUL, itemData);
				if (typeof window.dataLayer !== 'undefined') {
					window.dataLayer.push({
						'event': 'cartRemoved',
					});
				}
				// clickEventDL(event, itemData);
			});
		}).catch((e) => {
			this.dispatch(this.actions.REMOVE_FAILURE, e);
		});
	}
}

export default new CartModel();